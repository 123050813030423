.Wrapper {
  margin-top: 100px;
  margin-bottom: 92px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.Background {
  width: 70%;
  aspect-ratio: 2/1;
  object-fit: cover;
}

.SliderWrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ImageSlider {
  padding: 5px;
  width: 100%;
  aspect-ratio: 264/188;
  max-height: 188px;
}

.CarouselWrapper {
  margin-top: 36px;
  width: 70%;
  height: auto;
  position: relative;
  scrollbar-width: none;
}

.ItemTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
}

.ButtonLeft {
  max-height: 45px;
  top: 45%;
  left: 8px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  position: absolute;
  aspect-ratio: 1/1;
  height: 30%;
  border-radius: 50%;
  border: 1px solid #447323;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ButtonRight {
  max-height: 45px;
  top: 45%;
  transform: translate(50%, -50%);
  position: absolute;
  right: 8px;
  aspect-ratio: 1/1;
  height: 30%;
  border-radius: 50%;
  border: 1px solid #447323;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}

@media only screen and (max-width: 770px) {
  .Background {
    width: 100%;
  }
  .CarouselWrapper {
    width: 95%;
  }
}
