.Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding: 2rem;
  background: url("../../../../assets/images/BackgroundComponent3.png")
    no-repeat center/cover;
}

.Content {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.Infos {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.Info {
  width: 23%;
  height: auto;
  border-radius: 8px;
  opacity: 0.9;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
}

.InfoNumber {
  font-weight: 400;
  font-size: 80px;
  line-height: 72px;
  color: #e1641c;
}

.InfoText {
  margin-top: 24px;
  width: 80%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #262626;
}

.Desc {
  line-height: 1.5;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 770px) {
  .Content {
    width: 100%;
  }
  .Wrapper {
    display: flex;
    justify-content: center;
    padding: 0%;
  }
  .Infos {
    width: 100%;
    justify-content: center;
    gap: 10%;
    padding-bottom: 10%;
    flex-wrap: wrap;
  }
  .Info {
    width: 40%;
    margin-top: 10%;
  }
}
